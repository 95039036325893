import 'classlist-polyfill';
class ImageSwapException {
    constructor(message) {
        this.message = message;
    }
}
class ImageSwap {
    constructor(config) {
        this.container = config.container;
        this.swapContainer = config.swapContainer;
        if (this.swapContainer.tagName === 'A') {
            this.swapContainerLink = this.swapContainer;
            this.swapContainerImage = this.swapContainer.querySelector('img');
            if (this.swapContainerImage === null) {
                throw new ImageSwapException('No image found in swap container');
            }
        }
        else if (this.swapContainer.tagName === 'IMG') {
            this.swapContainerImage = this.swapContainerImage;
        }
        else {
            throw new ImageSwapException('Wrong swap container');
        }
        this.sourceSelector = config.sourceSelector;
        this.firstSourceItemActive = (config.firstSourceItemActive === false) ? false : true;
        if (config.duplicateSourceItemClassName) {
            this.duplicateSourceItemClassName = config.duplicateSourceItemClassName;
        }
        this.thumbnailDataAttrName = config.thumbnailDataAttrName ? config.thumbnailDataAttrName : 'thumbnail';
        if (config.originalDataAttrName) {
            this.originalDataAttrName = config.originalDataAttrName;
        }
        this.activeImageClassName = config.activeImageClassName ? config.activeImageClassName : 'active';
    }
    run() {
        this.initControls();
        this.initEventListeners();
    }
    initControls() {
        if (this.sourceSelector) {
            this.sourceItems = this.container.querySelectorAll(`${this.sourceSelector}`);
            if (this.sourceItems.length > 0 && this.firstSourceItemActive === true) {
                if (this.duplicateSourceItemClassName) {
                    for (let i = 0; i < this.sourceItems.length; i++) {
                        const element = this.sourceItems[i];
                        if (!element.classList.contains(this.duplicateSourceItemClassName)) {
                            this.sourceElementSetActive(element);
                            break;
                        }
                    }
                }
                else {
                    this.sourceElementSetActive(this.sourceItems[0]);
                }
            }
        }
    }
    initEventListeners() {
        if (this.sourceItems.length > 0) {
            for (let i = 0; i < this.sourceItems.length; i++) {
                this.sourceItems[i].addEventListener('click', (event) => this.clickHandler(event));
            }
        }
    }
    swapImage(element) {
        if (this.swapContainerLink) {
            if (this.originalDataAttrName && typeof element.dataset[this.originalDataAttrName] !== 'undefined') {
                this.swapContainerLink.href = element.dataset[this.originalDataAttrName];
            }
            else {
                this.swapContainerLink.href = element.href;
            }
        }
        this.swapContainerImage.src = element.dataset[this.thumbnailDataAttrName];
    }
    sourceElementSetActive(element) {
        if (this.currentSelectedSourceElement) {
            this.currentSelectedSourceElement.classList.remove(this.activeImageClassName);
        }
        this.swapImage(element);
        element.classList.add(this.activeImageClassName);
        this.currentSelectedSourceElement = element;
    }
    clickHandler(event) {
        event.preventDefault();
        const element = event.currentTarget;
        this.sourceElementSetActive(element);
    }
}
export { ImageSwap };
