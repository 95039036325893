/// <reference types="swiper"/>
import 'core-js/stable/object/assign';
import Swiper from 'swiper';
import { Navigation, Autoplay, Thumbs } from 'swiper/modules';
import { Data } from "../utils/data";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/thumbs";
class SwiperSliderComponent {
    constructor(config) {
        this.options = {};
        this.element = config.element;
        Object.assign(this.options, this.optionsFromDataAttributes());
        if (config.options) {
            Object.assign(this.options, config.options);
        }
        this.loadModules();
        this.run();
    }
    optionsFromDataAttributes() {
        const options = {};
        Object.assign(options, this.element.dataset);
        for (const optionsKey in options) {
            options[optionsKey] = Data.getData(options[optionsKey]);
        }
        return options;
    }
    loadModules() {
        if (!this.options.hasOwnProperty('modules')) {
            this.options.modules = [];
        }
        for (const optionsKey in this.options) {
            if (optionsKey === 'autoplay') {
                this.options.modules.push(Autoplay);
            }
            if (optionsKey === 'navigation') {
                this.options.modules.push(Navigation);
            }
            if (optionsKey === 'pagination') {
                this.options.modules.push(Navigation);
            }
            if (optionsKey === 'thumbs') {
                if (typeof this.options[optionsKey].el !== 'undefined') {
                    const thumbSlider = document.querySelector(this.options[optionsKey].el);
                    if (thumbSlider !== null) {
                        const thumbSliderInstance = new SwiperSliderComponent({
                            element: thumbSlider
                        }).getInstance();
                        this.options[optionsKey] = {
                            swiper: thumbSliderInstance
                        };
                        this.options.modules.push(Thumbs);
                    }
                }
            }
        }
        return;
    }
    getInstance() {
        return this.instance;
    }
    run() {
        this.instance = new Swiper(this.element, this.options);
        return this;
    }
}
export { SwiperSliderComponent };
