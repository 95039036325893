import 'core-js/stable/dom-collections/for-each';
import { ImageSwap } from './image.swap';
import { SwiperSliderComponent } from './swiper-slider';
import './../scss/main';
class Application {
    constructor() {
    }
    run() {
        this.initSlider();
        this.initSwapImage();
    }
    initSlider() {
        const sliderList = document.querySelectorAll('.j-slider-component');
        sliderList.forEach(slider => {
            new SwiperSliderComponent({
                element: slider,
            });
        });
    }
    initSwapImage() {
        const container = document.querySelector('.swap__container');
        if (container !== null) {
            const swapContainer = container.querySelector('.swap__image_container');
            if (swapContainer !== null) {
                new ImageSwap({
                    container: container,
                    swapContainer: swapContainer,
                    firstSourceItemActive: false,
                    sourceSelector: '.swap__image_item',
                    duplicateSourceItemClassName: 'swiper-slide-duplicate',
                }).run();
            }
        }
    }
}
document.addEventListener("DOMContentLoaded", function (event) {
    const application = new Application();
    application.run();
});
